import { useCallback, useEffect, useRef, useState } from 'react';
import { Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const YearsPicker = ({
  name,
  id,
  label,
  autoClose = false,
  className = '',
  handleDateChange = () => false,
  autoApply = false,
  hasDefaultDate = false,
  ...props
}) => {
  let datepickerRef = useRef(null);
  const [picker, setPicker] = useState(hasDefaultDate ? new Date() : undefined);
  //
  const applyDatepicker = useCallback(() => {
    let yearInstance = moment(picker);
    let startYear = yearInstance.startOf('year').format('YYYY-MM-DD');
    let endYear = yearInstance.endOf('year').format('YYYY-MM-DD');
    //
    handleDateChange([startYear, endYear]);
    //
    if(datepickerRef.current) {
      datepickerRef.current.setOpen(false);
    }
  }, [datepickerRef, picker, handleDateChange]);

  const onChange = useCallback((date) => {
    setPicker(date);
  }, []);

  const closeDatepicker = useCallback(() => {
    setPicker(new Date());
    if(datepickerRef.current) {
      datepickerRef.setOpen(false);
    }
  }, []);


  useEffect(() => {
    if (autoApply) {
      applyDatepicker();
    }
  }, [picker]);

  return (
    <div className="cursor-pointer">
      {label && (
        <Label className="form-label" htmlFor={id}>
          {label}
        </Label>
      )}
      <DatePicker
        {...props}
        selected={picker}
        id={id}
        name={name}
        className={`form-control cursor-pointer ${className}`}
        onChange={(date) => onChange(date)}
        showYearPicker
        dateFormat="yyyy"
        maxDate={new Date()}
        shouldCloseOnSelect={autoClose}
        ref={datepickerRef}
        onClickOutside={(e) => {
          if(datepickerRef.current) {
            datepickerRef.current.setFocus(true)
          }
        }}>
        {!autoApply && (
          <>
            <div className="clearfix"></div>
            <div className="custom-flex-row justify-content-between align-items-center w-100">
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 apply-dp"
                onClick={() => {
                  closeDatepicker();
                }}>
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 close-dp"
                onClick={() => applyDatepicker()}>
                Apply
              </Button.Ripple>
            </div>
          </>
        )}
      </DatePicker>
    </div>
  );
};

export default YearsPicker;
