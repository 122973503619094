import { domNotify } from '@src/@core/components/Notify/DomNotify';
import { store } from '@src/redux/store';
// import { refreshToken } from '@src/views/Auth/User/Login/store';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        retryDelay: 300,
        keepPreviousData: true,
        staleTime: 1000 * 30, // 30seconds
        cacheTime: 1000 * 30, //30 seconds
        //refetchInterval: 1000 * 5, //30 seconds
        refetchIntervalInBackground: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        suspense: false,
        onError: (err) => {
          if (err?.response?.status === 401) {
            domNotify({
              type: "warning",
              text: "Session Expired",
              smallDesc: "Session Expired: It will logout within 5 minutes .. please save any needed work",
              // callbacks: [() => store.dispatch(refreshToken())]
            })
          }
        }
      },
      mutations: {
        retry: 0,
        retryDelay: 300,
        onError: (err) => {
          /* if (err?.response?.status === 401) {
            logout();
          } */
        }
      },

    }
  });